import React from 'react'
import Heading from '../../Global/Heading'
import SmsRecords from './SmsRecords'
import useSessionTimeout from '../../Hooks/useSessionTimeout';
import SessionExpiredPopup from '../../Global/SessionExpiredPopup';

const SmsRecordsContent = () => {
    const { isSessionExpired, handleSessionExpired } = useSessionTimeout();

    if (isSessionExpired) {
        return <SessionExpiredPopup onClose={handleSessionExpired} />;
    }
    return (
        <div className='w-full flex flex-col justify-start items-end px-5 py-2'>
            <Heading heading="SMS Campaigns Record"
                description="View SMS campaign records" />
            <SmsRecords />


        </div>
    )
}

export default SmsRecordsContent
