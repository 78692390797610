import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CallRecords from '../Campaigns-Records/Call-Records/CallRecords';
// import EmailRecords from '../Campaigns-Records/Emails-Records/EmailRecords';
// import SmsRecords from '../Campaigns-Records/Sms-Records/SmsRecords'; 
// import SimpleBar from 'simplebar-react';
// import EmailRecords from '../Campaigns-Records/Emails-Records/EmailRecords';
import EditTemplateButton from '../Email-Template-Editor/EditTemplateButton';
import EditSmsTemplateButton from '../SMS-Template-Editor/EditSmsTemplateButton';

const CampaignTabs = () => {
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="custom-width flex flex-col gap-10 justify-start bg-white float-end">
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                            onChange={handleChange}
                            sx={{
                                '& .MuiTab-root.Mui-selected': {
                                    color: '#00a3b1',
                                },
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#00a3b1',
                                },
                            }}
                        >
                            <Tab label="Call Campaigns" value="1" />
                            <Tab label="Email Campaigns" value="2" />
                            <Tab label="SMS Campaigns" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ p: 0, width: '100%' }}>
                        <CallRecords />
                    </TabPanel>
                    <TabPanel value="2" sx={{ p: 0, width: '100%' }}>
                        <EditTemplateButton />
                        <CallRecords />
                    </TabPanel>
                    <TabPanel value="3" sx={{ p: 0, width: '100%' }}>
                        <EditSmsTemplateButton />
                        <CallRecords />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
};

export default CampaignTabs;
