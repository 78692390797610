import React, { useState } from 'react'
import Heading from '../Global/Heading'
import AddRecord from './AddRecord'
import SoldFilestable from './SoldFilestable'
import useSessionTimeout from '../Hooks/useSessionTimeout';
import SessionExpiredPopup from '../Global/SessionExpiredPopup';

const ManageRecordsContent = () => {
    const [records, setRecords] = useState([]);
    const { isSessionExpired, handleSessionExpired } = useSessionTimeout();
    if (isSessionExpired) {
        return <SessionExpiredPopup onClose={handleSessionExpired} />;
    }
    const handleAddRecord = (newRecord) => {
        setRecords((prevRecords) => [...prevRecords, newRecord]);
    };


    return (
        <div className='w-full flex flex-col justify-start items-end px-5 py-2'>
            <Heading heading="Manage Records"
                description="Add and manage your records" />
            <AddRecord onSaveRecord={handleAddRecord} />
            <SoldFilestable records={records} />

        </div>
    )
}

export default ManageRecordsContent
