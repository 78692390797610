import React from 'react'
import Heading from '../Global/Heading'
import SkipFilesTable from './SkipFilesTable'
import GenerateSkipButton from './GenerateSkipButton'
import useSessionTimeout from '../Hooks/useSessionTimeout';
import SessionExpiredPopup from '../Global/SessionExpiredPopup';

const ManageSkipContent = () => {
    const { isSessionExpired, handleSessionExpired } = useSessionTimeout();

    if (isSessionExpired) {
        return <SessionExpiredPopup onClose={handleSessionExpired} />;
    }
    return (
        <div className='w-full overflow-hidden flex flex-col justify-start items-end px-5 py-2'>
            <Heading heading="Manage Skip Files"
                description="Create, Veiw, Delete your skip files " />
            <GenerateSkipButton />
            <SkipFilesTable />
        </div>
    )
}

export default ManageSkipContent
