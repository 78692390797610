import React from 'react';
import useSessionTimeout from '../Hooks/useSessionTimeout';
import Heading from '../Global/Heading';
import AddAgentButton from './AddAgentButton';
import AgentsTable from './AgentsTable';
import SessionExpiredPopup from '../Global/SessionExpiredPopup';

const ManageAgentsContent = () => {
  const { isSessionExpired, handleSessionExpired } = useSessionTimeout();

  if (isSessionExpired) {
    return <SessionExpiredPopup onClose={handleSessionExpired} />;
  }

  return (
    <div className='w-full flex flex-col justify-start items-end px-5 py-2'>
      <Heading 
        heading="Manage Agents" 
        description="Add and manage your agents" 
      />
      <AddAgentButton />
      <AgentsTable />
    </div>
  );
};

export default ManageAgentsContent;
