import React from 'react'
import Heading from '../../Global/Heading'
// import CallRecords from './CallRecords'
import useSessionTimeout from '../../Hooks/useSessionTimeout';
import SessionExpiredPopup from '../../Global/SessionExpiredPopup';
import CampaignTabs from '../../Campaigns/CampaignsTabs';
import AddBroadcastButton from '../../Campaigns/AddBroadcastButton';


const CallsRecordsContent = () => {
    const { isSessionExpired, handleSessionExpired } = useSessionTimeout();

    if (isSessionExpired) {
        return <SessionExpiredPopup onClose={handleSessionExpired} />;
    }
    return (

        <div className='w-full flex flex-col justify-start items-end float-end px-5 py-2'>
            <Heading heading="Call Campaigns"
                description="View and Start call campaigns" />
            <AddBroadcastButton />
            <CampaignTabs />
            {/* //     <CallRecords /> */}
        </div>
    )
}

export default CallsRecordsContent
