import React, { useState, useEffect } from 'react';
import CallsPieChart from './CallsPieChart';
import StartConfirmation from './StartConfirmation';
import { useUserContext } from '../../Global/UserContext';
import { REACT_API_URL } from '../../../config';

const CallRecords = () => {
    const { id, userId } = useUserContext();
    const [showStartConfirmation, setShowStartConfirmation] = useState(false);
    const [fileRecord, setFileRecord] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFileId, setSelectedFileId] = useState(null);
    const pageSize = 30;

    const [records, setRecords] = useState({}); // Object to store each file's stats

    useEffect(() => {
        const fetchFileRecords = async () => {
            try {
                const token = localStorage.getItem('token');
                const userParam = userId ? `agentId=${id}` : `userId=${id}`;
                const response = await fetch(`${REACT_API_URL}/broadcast-files?fileType=call&${userParam}&page=${currentPage}&pageSize=${pageSize}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!response.ok) throw new Error(`Error: ${response.status}`);

                const result = await response.json();
                const items = result.items || [];

                setFileRecord(items);
                setTotalRecords(result.total || 0);
                setTotalPages(Math.ceil((result.total || 0) / pageSize));

                if (items.length > 0) {
                    setSelectedFileId(items[0].id);
                    items.forEach((item) => fetchStats(item.id));
                }
            } catch (error) {
                console.error('Error fetching file records:', error);
            }
        };

        fetchFileRecords();
    }, [currentPage, id, userId]);

    const fetchStats = async (broadcastFileId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${REACT_API_URL}/broadcast-call-records/stats?broadcastFileId=${broadcastFileId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            setRecords((prevRecords) => ({
                ...prevRecords,
                [broadcastFileId]: {
                    total: result.total || 0,
                    answered: result.stats["Call Answered"]?.count || 0,
                    machineAnswered: result.stats["Machine Answered"]?.count || 0,
                    disconnected: result.stats["Disconnected"]?.count || 0,
                },
            }));
        } catch (error) {
            console.error('Error fetching stats:', error);
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleNext = () => handlePageChange(currentPage + 1);
    const handlePrev = () => handlePageChange(currentPage - 1);
    const disablePrev = currentPage === 1;
    const disableNext = currentPage === totalPages;
    const showPagination = totalRecords > pageSize;

    return (
        <div className='custom-width flex flex-wrap gap-5 mt-4'>
            {fileRecord.length === 0 ? (
                <p className="text-center text-gray-500 w-full">No Record Found</p>
            ) : (
                fileRecord.map((item) => (
                    <div key={item?.id} className='relative p-5 border rounded-md flex flex-col gap-5'>
                        <div className='absolute right-0 mt-[-20px] bg-[#F0CE19] py-1 px-5 rounded-tr-md rounded-bl-md text-white tracking-wide text-xs'>Pending</div>
                        <div className='w-full flex  text-[#5E5B5B] justify-between mt-3'>
                            <span>
                                <h2 className='font-semibold'>{item?.fileName}</h2>
                                <p className='text-xs pr-3 mt-1'>{item?.description}</p>
                            </span>
                            <button
                                onClick={() => {
                                    setSelectedFileId(item.id);
                                    setShowStartConfirmation(true);
                                }}
                                className='w-fit text-nowrap h-9 border rounded-md bg-[#00a3b1] text-sm font-medium text-white px-4 py-1 hover:border hover:border-[#e4e4e7] hover:text-[#00a3b1] hover:bg-transparent'>
                                Start Campaign
                            </button>
                            {showStartConfirmation && selectedFileId === item.id && (
                                <StartConfirmation
                                    onClose={() => setShowStartConfirmation(false)}
                                    selectedFileId={selectedFileId}
                                />
                            )}
                        </div>

                        <div className='w-full flex items-center justify-center gap-4'>
                            <div className='flex flex-col items-center justify-center pb-3'>
                                <h3 className='text-3xl '>{records[item.id]?.total || 0}</h3>
                                <p className='text-sm font-medium text-[#5E5B5B]'>Total Contacts</p>
                            </div>
                            <div className='flex flex-col items-center justify-center'>
                                <h3 className='text-3xl font-medium'>{records[item.id]?.answered || 0}</h3>
                                <p className='text-sm font-medium text-[#5E5B5B]'>Answered</p>
                                <span className='w-1 h-1 rounded-full p-[4px] mt-2 bg-[#00C49F]'></span>
                            </div>
                            <div className='flex flex-col items-center justify-center'>
                                <h3 className='text-3xl font-medium'>{records[item.id]?.machineAnswered || 0}</h3>
                                <p className='text-sm font-medium text-[#5E5B5B]'>Voicemails</p>
                                <span className='w-1 h-1 rounded-full p-[4px] mt-2 bg-[#FFBB28]'></span>
                            </div>
                            <div className='flex flex-col items-center justify-center'>
                                <h3 className='text-3xl font-medium'>{records[item.id]?.disconnected || 0}</h3>
                                <p className='text-sm font-medium text-[#5E5B5B]'>Disconnected</p>
                                <span className='w-1 h-1 rounded-full p-[4px] mt-2 bg-[#FF8042]'></span>
                            </div>
                        </div>
                        <div className='w-full flex items-center justify-start h-[110px]'>
                            <CallsPieChart
                                total={records[item.id]?.total || 0}
                                answered={records[item.id]?.answered || 0}
                                machineAnswered={records[item.id]?.machineAnswered || 0}
                                disconnected={records[item.id]?.disconnected || 0}
                            />
                        </div>
                    </div>
                ))
            )}
            {showPagination && (
                <div className="w-full flex justify-between items-center my-2">
                    <button
                        onClick={handlePrev}
                        disabled={disablePrev}
                        className={`w-[100px] px-4 py-2 bg-gray-200 ${disablePrev ? 'cursor-not-allowed opacity-50' : ''}`}
                    >
                        Previous
                    </button>
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        onClick={handleNext}
                        disabled={disableNext}
                        className={`w-[100px] px-4 py-2 bg-gray-200 ${disableNext ? 'cursor-not-allowed opacity-50' : ''}`}
                    >
                        Next
                    </button>
                </div>
            )}
        </div>
    );
};

export default CallRecords;
