import React from 'react'
const EmailRecords = () => {
    return (
        <div className='w-[calc(100%-210px)] p-10  rounded-md flex mt-2'>
            <p className="text-center text-gray-500 w-full">No Record Found</p>
        </div>
    )
}

export default EmailRecords
