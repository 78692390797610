import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isTokenExpired } from '../utils/tokenUtils';

const useSessionTimeout = () => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token || isTokenExpired(token)) {
      setIsSessionExpired(true);
    }
  }, []);

  const handleSessionExpired = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  return { isSessionExpired, handleSessionExpired };
};

export default useSessionTimeout;
