import React, { useState, useEffect } from 'react'
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import file from '../Assets/file.png'
import view from '../Assets/view.png'
import update from '../Assets/update.png'
import del from '../Assets/del.png'
import download from '../Assets/download.png'
import { Link } from 'react-router-dom';
import AccountDetails from './AccountDetails';
import UpdateAccount from './UpdateAccount';
import FileDeleteConfirmation from './FileDeleteConfirmation';
import { useUserContext } from '../Global/UserContext';
import { REACT_API_URL } from '../../config'


const ViewAccountsTable = () => {
    const { id, userId } = useUserContext();
    const [record, setRecord] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [selectedFileId, setSelectedFileId] = useState(null);
    const [showDetailsPopUP, setShowDetailsPopUP] = useState(false);
    const [showAccountUpdatePopUP, setShowAccountUpdatePopUP] = useState(false);
    const [showFileDeleteConfirmation, setShowFileDeleteConfirmation] = useState(false);;
    const pageSize = 100;

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let response;

            if (userId) {
                try {
                    response = await fetch(`${REACT_API_URL}/file-management?agentId=${id}&page=${currentPage}&pageSize=${pageSize}&all=false`);
                    const result = await response.json();
                    setRecord(result.items);
                    setTotalRecords(result.total);
                    setTotalPages(Math.ceil(result.total / pageSize));
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            } else {
                try {
                    response = await fetch(`${REACT_API_URL}/file-management?userId=${id}&page=${currentPage}&pageSize=${pageSize}&all=false`);
                    const result = await response.json();
                    setRecord(result.items);
                    setTotalRecords(result.total);
                    setTotalPages(Math.ceil(result.total / pageSize));
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData(); // Don't forget to call the function
    }, [userId, id, currentPage, pageSize]); // Add necessary dependencies


    // Pagination
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleNext = () => {
        handlePageChange(currentPage + 1);
    };

    const handlePrev = () => {
        handlePageChange(currentPage - 1);
    };

    const disablePrev = currentPage === 1;
    const disableNext = currentPage === totalPages;
    const showPagination = totalRecords > pageSize;

    const handleViewClick = (id) => {
        setSelectedFileId(id);
        setShowDetailsPopUP(true);
    };

    const handleUpdate = (id) => {
        setSelectedFileId(id);
        setShowAccountUpdatePopUP(true);
    };
    const handleDeleteClick = (id) => {
        setSelectedFileId(id);
        setShowFileDeleteConfirmation(true);
    };

    return (
        <>
            {loading ? (
                <div className="w-full flex justify-center items-center h-80">
                    <p className="text-gray-500">Loading...</p>
                </div>
            ) : (
                <SimpleBar className="custom-width bg-white h-auto mt-5 ">
                    <table className="w-full text-sm text-left text-gray-500 rounded-md border">
                        <thead className="text-nowrap text-xs text-gray-700 bg-gray-50 border-b-2">
                            <tr>
                                <th scope="col" className="px-4 py-2">ID</th>
                                <th scope="col" className="px-4 py-2">File Name</th>
                                <th scope="col" className="px-4 py-2">Description</th>
                                <th scope="col" className="px-4 py-2">Date</th>
                                <th scope="col" className="px-4 py-2">Uploaded By</th>
                                <th scope="col" className="px-4 py-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="text-nowrap text-sm">
                            {record.length === 0 ? (
                                <tr>
                                    <td colSpan="6" className="px-4 py-4 text-center text-gray-500">
                                        No Data Found
                                    </td>
                                </tr>
                            ) : (
                                record.map((item, index) => (
                                    <tr key={index} className='hover:bg-gray-100'>
                                        <td className="px-4 py-2">{item?.id}</td>
                                        <td className="px-4 py-2 flex items-center gap-2">
                                            <img className="w-4 h-5" src={file} alt="File" />
                                            <p>{item?.fileName}</p>
                                        </td>
                                        <td className="px-4 py-2" title={item?.description}>
                                            {item?.description.split(' ').slice(0, 4).join(' ') + (item.description.split(' ').length > 4 ? '...' : '')}
                                        </td>

                                        <td className="px-4 py-2">
                                            {new Date(item.createdAt).toLocaleString('en-US', {
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                            })}
                                        </td>
                                        <td className="px-4 py-2">John Doe</td>
                                        <td className="px-4 py-2 flex items-center justify-start gap-2">
                                            {/* <Link title='View Details' onClick={() => handleViewClick(item.id)} ><img src={view} alt="View" className="w-4" /></Link> */}
                                            <Link title='Update Accounts' onClick={() => handleUpdate(item.id)}><img src={update} alt="Update" className="w-4" /></Link>
                                            <a href={item?.fileUrl} download={item?.fileUrl} title='Download'><img src={download} alt="Update" className="w-4" /></a>
                                            <Link title='Delete Accounts' onClick={() => handleDeleteClick(item.id)}><img src={del} alt="Delete" className="w-4" /></Link>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    {/* Pagination Controls */}
                    {showPagination && (
                        <div className="flex justify-between items-center my-2">
                            <button
                                onClick={handlePrev}
                                disabled={disablePrev}
                                className={`w-[100px] px-4 py-2 bg-gray-200 ${disablePrev ? 'cursor-not-allowed opacity-50' : ''}`}
                            >
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                onClick={handleNext}
                                disabled={disableNext}
                                className={`w-[100px] px-4 py-2 bg-gray-200 ${disableNext ? 'cursor-not-allowed opacity-50' : ''}`}
                            >
                                Next
                            </button>
                        </div>
                    )}
                </SimpleBar>
            )}
            {/* Show FileDetails Popup */}
            {showDetailsPopUP && (
                <AccountDetails fileId={selectedFileId} onClose={() => setShowDetailsPopUP(false)} />
            )}
            {/* Show UpdateAccount Popup */}
            {showAccountUpdatePopUP && (
                <UpdateAccount fileId={selectedFileId} onClose={() => setShowAccountUpdatePopUP(false)} />
            )}
            {/* Show Delete file Confirmation */}
            {showFileDeleteConfirmation && (
                <FileDeleteConfirmation
                    fileId={selectedFileId}
                    onClose={() => setShowFileDeleteConfirmation(false)}
                    onDeleteSuccess={() => {
                        setRecord(prevRecords => prevRecords.filter(item => item.id !== selectedFileId));
                        setShowFileDeleteConfirmation(false);
                    }}
                />
            )}
        </>

    );
}

export default ViewAccountsTable;