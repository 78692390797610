import React from 'react'
import Heading from '../Global/Heading'
import AddClientButton from './AddClientButton'
import ClientsTable from './ClientsTable'
import useSessionTimeout from '../Hooks/useSessionTimeout';
import SessionExpiredPopup from '../Global/SessionExpiredPopup';

const ManageClientsContent = () => {
    const { isSessionExpired, handleSessionExpired } = useSessionTimeout();

    if (isSessionExpired) {
        return <SessionExpiredPopup onClose={handleSessionExpired} />;
    }
    return (
        <div className='w-full flex flex-col justify-start items-end px-5 py-2'>
            <Heading heading="Manage Clients"
                description="Add and manage your clients" />
            <AddClientButton />
            <ClientsTable />
        </div>
    )
}

export default ManageClientsContent
