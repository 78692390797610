import React from 'react'
import Heading from '../Global/Heading'
import AddBroadcastButton from './AddBroadcastButton'
import useSessionTimeout from '../Hooks/useSessionTimeout';
import SessionExpiredPopup from '../Global/SessionExpiredPopup';
import CampaignTabs from './CampaignsTabs';

const ViewCampaignsContent = () => {
  const { isSessionExpired, handleSessionExpired } = useSessionTimeout();

  if (isSessionExpired) {
    return <SessionExpiredPopup onClose={handleSessionExpired} />;
  }

  return (
    <div className='w-full flex flex-col justify-start items-end px-5 py-2'>
      <Heading heading="Campaigns"
        description="Create, View, Delete your campaigns" />
      <AddBroadcastButton />
      <CampaignTabs/>
    </div>
  )
}

export default ViewCampaignsContent
