import React from 'react'
// import Profile from '../../Global/Profile'
import Heading from '../../Global/Heading'
import EmailRecords from './EmailRecords'
import EditTemplateButton from '../../Email-Template-Editor/EditTemplateButton'
import useSessionTimeout from '../../Hooks/useSessionTimeout';
import SessionExpiredPopup from '../../Global/SessionExpiredPopup';

const EmailsRecordsContent = () => {
    const { isSessionExpired, handleSessionExpired } = useSessionTimeout();

    if (isSessionExpired) {
        return <SessionExpiredPopup onClose={handleSessionExpired} />;
    }
    return (
        <div className='w-full flex flex-col justify-start items-end px-5 py-2'>
            {/* <Profile /> */}
            <Heading heading="Email Campaigns Record"
                description="View email campaign records" />
            <EditTemplateButton />
            <EmailRecords />

        </div>
    )
}

export default EmailsRecordsContent
