import React from 'react'
import { useNavigate } from 'react-router-dom'


const EditSmsTemplateButton = () => {
    const navigate = useNavigate();
    return (
        <div className='w-full flex justify-end my-2 pr-[10px]'>
            <button onClick={() => navigate('/smstemplateeditor')} className='w-fit border rounded-md bg-[#00a3b1] text-sm font-medium text-white px-4 py-2 hover:border hover:border-[##e4e4e7] hover:text-[#00a3b1] hover:bg-transparent'>Edit SMS Template</button>
        </div>
    )
}

export default EditSmsTemplateButton
