import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './style.css';
import Dashboard from './Screens/Dashboard';
import ViewAllAccounts from './Screens/ViewAllAccounts';
import ManageSkipFiles from './Screens/ManageSkipFiles';
import Login from './Screens/Login';
import PrivateRoute from './Components/Global/PrivateRoute';
import ManageAgents from './Screens/ManageAgents';
import Signup from './Screens/Signup';
import ThanksSignUp from './Screens/ThanksSignup';
import SendOTP from './Screens/SendOTP';
import VeiwCampaigns from './Screens/VeiwCampaigns';
import Verification from './Screens/Verification';
import ResetPassword from './Screens/ResetPassword';
import PasswordUpdated from './Screens/PasswordUpdated';
import MyProfile from './Screens/MyProfile';
import ManageClients from './Screens/ManageClients';
import AgentLogin from './Screens/AgentLogin';
import ManageRecords from './Screens/ManageRecords';
import EmailTemplateEditor from './Screens/EmailTemplateEditor';
import CallsRecords from './Screens/CallsRecords';
import EmailsRecords from './Screens/EmailsRecords';
import SmsRecords from './Screens/SmsRecords';
import SmsTemplateEditor from './Screens/SmsTemplateEditor';
import MainLayout from './Components/Global/MainLayout';  // Import the new MainLayout component

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Routes with no Profile and Sidebar */}
        <Route path="/signup" element={<Signup />} />
        <Route path="/" element={<Login />} />
        <Route path="/agentlogin" element={<AgentLogin />} />
        <Route path="/thanksforsignup" element={<ThanksSignUp />} />
        <Route path="/identify" element={<SendOTP />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/passwordupdated" element={<PasswordUpdated />} />

        {/* Routes with Profile and Sidebar*/}
        <Route element={<MainLayout />}>
          <Route path="/myprofile" element={<PrivateRoute><MyProfile /></PrivateRoute>} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/manageaccounts" element={<PrivateRoute><ViewAllAccounts /></PrivateRoute>} />
          <Route path="/manageskipfiles" element={<PrivateRoute><ManageSkipFiles /></PrivateRoute>} />
          <Route path="/manageagents" element={<PrivateRoute><ManageAgents /></PrivateRoute>} />
          <Route path="/managecampaigns" element={<PrivateRoute><VeiwCampaigns /></PrivateRoute>} />
          <Route path="/manageclients" element={<PrivateRoute><ManageClients /></PrivateRoute>} />
          <Route path="/managerecords" element={<PrivateRoute><ManageRecords /></PrivateRoute>} />
          <Route path="/emailtemplateeditor" element={<PrivateRoute><EmailTemplateEditor /></PrivateRoute>} />
          <Route path="/callsrecords" element={<PrivateRoute><CallsRecords /></PrivateRoute>} />
          <Route path="/emailsrecords" element={<PrivateRoute><EmailsRecords /></PrivateRoute>} />
          <Route path="/smsrecords" element={<PrivateRoute><SmsRecords /></PrivateRoute>} />
          <Route path="/smstemplateeditor" element={<PrivateRoute><SmsTemplateEditor /></PrivateRoute>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
