import React from 'react'
import Heading from '../Global/Heading'
import PersonalInfo from './PersonalInfo'
import SecondaryHeading from './SecondaryHeading'
import ResetPassword from './ResetPassword'
import useSessionTimeout from '../Hooks/useSessionTimeout';
import SessionExpiredPopup from '../Global/SessionExpiredPopup';

const MyProfileContent = () => {
    const { isSessionExpired, handleSessionExpired } = useSessionTimeout();

    if (isSessionExpired) {
        return <SessionExpiredPopup onClose={handleSessionExpired} />;
    }
    return (
        <div className='w-full flex flex-col justify-start items-end p-5'>
            <Heading heading="My Profile"
                description="See Personal information and Reset password here" />
            <div className='custom-width flex gap-5 mt-5'>
                <div className='flex flex-col w-1/2'>
                    <SecondaryHeading heading="Personal Information" />
                    <PersonalInfo />
                </div>
                <div className='flex flex-col w-1/2'>
                    <SecondaryHeading heading="Reset Password" />
                    <ResetPassword />
                </div>
            </div>
        </div>
    )
}

export default MyProfileContent
