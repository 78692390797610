import React from 'react'
import logo from '../Assets/logo.png'
import Activity from '../Assets/Activity.png'
import box from '../Assets/Combined-Shape.png'
import upload from '../Assets/Icon.png'
import wallet from '../Assets/Wallet.png'
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Dropdown from '../Custom-Dropdown/Dropdown'
import { NavLink } from 'react-router-dom'


const Sidebar = () => {

    return (
        <div className='fixed overflow-y-hidden w-[210px] bg-white flex flex-col justify-start items-center py-5 h-screen border-r'>
            <img src={logo} alt="E-Livery" className='w-[160px] ' />
            <SimpleBar className='mt-5 overflow-y-auto overflow-x-hidden w-full flex flex-col justify-center items-center pl-7 max-h-[85vh]'>

                <NavLink to="/dashboard"
                    style={({ isActive }) => ({
                        color: isActive ? '#00a3b1' : '#525151',
                    })}>
                    <span className='flex items-center  gap-1 mb-2'>
                        <img src={box} alt="Category" className='w-3' />
                        <h5 className=' text-[14px] font-[500]'>Dashboard</h5>
                    </span>
                </NavLink>
                <Dropdown
                    titleImg={upload}
                    title="Account Upload"
                    img1={wallet}
                    img2={Activity}
                    opt1="Manage Accounts"
                    opt2="Manage Skip File"
                    path1="/manageaccounts"
                    path2="/manageskipfiles"

                />
                <Dropdown
                    titleImg={box}
                    title="Agents"
                    img1={wallet}
                    opt1="Manage Agents"
                    path1="/manageagents"

                />
                <Dropdown
                    titleImg={box}
                    title="Campaigns"
                    // img1={upload}
                    img1={Activity}
                    // opt1="Broadcast File"
                    opt1="Manage Campaigns"
                    path1="/managecampaigns"
                />
                {/* <Dropdown
                    titleImg={box}
                    title="Campaigns "
                    img1={wallet}
                    img2={wallet}
                    img3={wallet}
                    opt1="Call Campaigns"
                    opt2="Email Campaigns"
                    opt3="Sms Campaigns"
                    path1="/callsrecords"
                    path2="/emailsrecords"
                    path3="/smsrecords"
                /> */}

                <Dropdown
                    titleImg={box}
                    title="Sales Manager"
                    img1={upload}
                    img2={upload}
                    opt1="Manage Clients"
                    opt2="Manage Records"
                    path1="/manageclients"
                    path2="/managerecords"
                />

            </SimpleBar>

        </div>
    )
}

export default Sidebar
