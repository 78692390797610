import React from 'react'
import Heading from '../Global/Heading'
import ViewAccountsHeader from './ViewAccountsHeader'
import UploadAccountButton from './UploadAccountButton'
import VeiwAccountsTable from './VeiwAccountsTable'
import useSessionTimeout from '../Hooks/useSessionTimeout';
import SessionExpiredPopup from '../Global/SessionExpiredPopup';

const ManageAccountsContent = () => {
    const { isSessionExpired, handleSessionExpired } = useSessionTimeout();

    if (isSessionExpired) {
        return <SessionExpiredPopup onClose={handleSessionExpired} />;
    }
    return (
        <div className='w-full overflow-hidden flex flex-col justify-start items-end px-5 py-2 '>
            <Heading heading="Manage Accounts"
                description="Create, Veiw, Delete your accounts" />
            <UploadAccountButton />
            <ViewAccountsHeader />
            <VeiwAccountsTable />
        </div>
    )
}

export default ManageAccountsContent
